import { STATES } from '../../../constants/user';
import { TabWidgetType } from '../types/Filter.Helper';

const widgetData: TabWidgetType[] = [
  {
    id: '1',
    text: 'In Progress',
    value: STATES.PROGRESS,
    subText: '0',
    isDue: false,
    isSelected: false,
  },
  {
    id: '2',
    text: 'OverDue',
    value: STATES.OVERDUE,
    subText: '0',
    isDue: true,
    isSelected: false,
  },
  {
    id: '3',
    text: 'Assigned',
    value: STATES.ASSIGNED,
    subText: '0',
    isDue: false,
    isSelected: false,
  },
  {
    id: '4',
    text: 'Completed',
    value: STATES.COMPLETED,
    subText: '0',
    isDue: false,
    isSelected: false,
  },
  {
    id: '5',
    text: 'All courses',
    value: STATES.ALL,
    subText: '0',
    isDue: false,
    isSelected: false,
  },
  {
    id: '6',
    text: 'Recommended',
    value: STATES.RECOMMENDED,
    isDue: false,
    isSelected: false,
  },
];
export default widgetData;
