import { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { ErrorDisplay } from '../../../../../../components/DataTables';
import { Loader } from '../../../../../../components/Loader/Loader';
import { ERROR_TYPES } from '../../../../../../constants/error-types.constants';
import { useAppSelector } from '../../../../../../hooks';
import { useStrapiDataHelper } from '../../../../../../hooks/useStrapiData';
import { RootState } from '../../../../../../store';
import useCourseDetailsQuery from '../../../../hooks/useCourseDetailsQuery';
import useGetCourseModulesQuery from '../../../../hooks/useGetCourseModulesQuery';
import SingleModuleDisplay from './components/SingleModuleDisplay/SingleModuleDisplay';
import SingleModuleHeader from './components/SingleModuleHeader.tsx/SingleModuleHeader';
import { SideBarLayoutPageContentWrapper } from '../../../../../../components/Wrapper';
import axios from 'axios';
import { LEARNING_JOURNEY_ERROR_CODE } from '../../../../../learning-journeys/constants/learning-journey';
import useLearningJourneyMetaQuery from '../../../../../learning-journeys/hooks/use-learning-journey-meta-query';
import { USHGAPIError } from '../../../../../../types';
import useSessionTracker from '../../../../../../hooks/use-session-tracker-hook';
const SingleModuleComponent = () => {
  useSessionTracker();
  const { id, journeyId } = useParams();
  const navigate = useNavigate();
  const strapidata: any = useStrapiDataHelper();
  const strapiErrorMessage = strapidata.errorsMap;
  // Query to get course details and modules.
  const coursesQuery = useCourseDetailsQuery({ id });
  const modulesQuery = useGetCourseModulesQuery({ id });
  const learningJourneyMetaQuery = useLearningJourneyMetaQuery({
    courseId: `${id}`,
    journeyId: `${journeyId}`,
    enabled: !!journeyId,
  });
  // get current module index from single course
  const singleCourseSelector = (state: RootState) => state.singleCourse;
  const { currentModuleDetails } = useAppSelector(singleCourseSelector);

  // loading state of queries
  const isQueryLoading =
    coursesQuery.isLoading ||
    modulesQuery.isLoading ||
    (journeyId && learningJourneyMetaQuery.isLoading);

  useEffect(() => {
    if (coursesQuery.data) {
      const { isAssigned } = coursesQuery.data;
      if (!isAssigned) {
        navigate(`/courses/${id}`);
      }
    }
  }, [coursesQuery.data]);
  // Loading State
  if (isQueryLoading) {
    return <Loader />;
  }

  if (!!journeyId && learningJourneyMetaQuery.isError) {
    if (axios.isAxiosError(learningJourneyMetaQuery.error)) {
      const data: USHGAPIError | undefined = learningJourneyMetaQuery.error.response
        ?.data as unknown as USHGAPIError;
      if (
        data &&
        (data.errorCode === LEARNING_JOURNEY_ERROR_CODE.LEARNING_JOURNEY_NOT_FOUND ||
          data.errorCode === LEARNING_JOURNEY_ERROR_CODE.COURSE_NOT_PART_OF_LEARNING_JOURNEY)
      ) {
        navigate(`/courses/${id}`);
      } else {
        return (
          <ErrorDisplay
            refetch={learningJourneyMetaQuery.refetch}
            allowsRefetch={true}
            isRefetching={learningJourneyMetaQuery.isRefetching}
            message={strapiErrorMessage['COURSES_ERROR']}
            type={ERROR_TYPES.SOMETHING_WENT_WRONG}
            className="h-full"
          />
        );
      }
    }
  }
  // if course details query fails
  if (coursesQuery.isError) {
    return (
      <ErrorDisplay
        refetch={coursesQuery.refetch}
        allowsRefetch={true}
        isRefetching={coursesQuery.isRefetching}
        message={strapiErrorMessage['COURSES_ERROR']}
        type={ERROR_TYPES.SOMETHING_WENT_WRONG}
      />
    );
  }
  // if modules query fails
  if (modulesQuery.isError) {
    return (
      <ErrorDisplay
        refetch={modulesQuery.refetch}
        allowsRefetch={true}
        isRefetching={modulesQuery.isRefetching}
        message={strapiErrorMessage['MODULES_ERROR']}
        type={ERROR_TYPES.SOMETHING_WENT_WRONG}
      />
    );
  }

  // if both query is executed successfully
  return (
    <div className="relative w-full">
      <SideBarLayoutPageContentWrapper className="relative">
        <div className="absolute w-full ">
          <SingleModuleHeader
            title={currentModuleDetails.name}
            courseName={coursesQuery.data.name}
            courseCategory={coursesQuery.data.category}
            courseDescription={currentModuleDetails.description}
          />
          <div className="flex-col justify-start py-5 text-white md:px-6 lg:px-[5%] xl:px-[7.5%]">
            <SingleModuleDisplay
              modulesQuery={modulesQuery}
              courseId={id}
              courseCategory={coursesQuery.data.category}
              isAlreadyCourseCompleted={coursesQuery.data.isCompleted}
            />
          </div>
        </div>
      </SideBarLayoutPageContentWrapper>
    </div>
  );
};

export default SingleModuleComponent;
