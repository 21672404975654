const QUERY_KEYS = {
  listAllLearningJourneys: ({ username, tab }: { username: string; tab: string }) => {
    return [
      {
        scope: 'learning-journeys',
        item: 'list-all',
        username: username,
        tab: tab,
      },
    ];
  },
  learningJourneyDetail: ({ username, id }: { username: string; id: string }) => {
    return [
      {
        scope: 'learning-journeys',
        item: 'detail',
        username: username,
        id,
      },
    ];
  },
  listLearningJourneyCourses: ({ username, id }: { username: string; id: string }) => {
    return [
      {
        scope: 'learning-journeys',
        item: 'list-learning-journey-courses',
        username: username,
        id,
      },
    ];
  },
  learningJourneyMetaData: ({ journeyId, courseId }: { journeyId: string; courseId: string }) => {
    return [
      {
        scope: 'learning-journeys',
        item: 'meta-data',
        journeyId,
        courseId,
      },
    ];
  },
};

const QUERY_STALE_TIME_MS = {
  learningJourneyDetail: 30000,
  listAllLearningJourneys: 30000,
  listLearningJourneyCourses: 30000,
};

export { QUERY_KEYS, QUERY_STALE_TIME_MS };
