const LEARNING_JOURNEY_BASE_PATH = '/learning-journey';

const PATHS = {
  LEARNING_JOURNEY: {
    LIST: `${LEARNING_JOURNEY_BASE_PATH}/v1`,
    DETAIL: ({ id }: { id: string }) => `${LEARNING_JOURNEY_BASE_PATH}/v1/${id}`,
    COURSES: ({ id }: { id: string }) => `${LEARNING_JOURNEY_BASE_PATH}/v1/${id}/courses`,
    META_DATA: ({ journeyId, id }: { journeyId: string; id: string }) =>
      `${LEARNING_JOURNEY_BASE_PATH}/v1/${journeyId}/courses/${id}`,

    GET_TEAMS: `${LEARNING_JOURNEY_BASE_PATH}/v1/teams`,
    GET_USERS: `${LEARNING_JOURNEY_BASE_PATH}/v1/users`,
    ASSIGN_TEAM: `${LEARNING_JOURNEY_BASE_PATH}/v1/team`,
    ASSIGN_USERS: `${LEARNING_JOURNEY_BASE_PATH}/v1/users`,
    ASSIGN_SELF: `${LEARNING_JOURNEY_BASE_PATH}/v1/self`,
  },
};

export { PATHS };
