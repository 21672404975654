import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';
import { UsersStatsFilter } from '../../teams/types/interface';
import { PATHS } from '../constants/api';
import type {
  GetLearningJourneyDetail,
  ListAllLearningJourneyCoursesResponse,
  ListAllLearningJourneysResponse,
} from '../types/api';

type ListAllLearningJourneysParams = {
  pageSize: number;
  pageOffset: number;
  tab: string;
  username?: Partial<UsersStatsFilter>;
};

const listAllLearningJourneys = async (
  params: ListAllLearningJourneysParams
): Promise<ListAllLearningJourneysResponse> => {
  const { pageSize, pageOffset, tab, username } = params;

  const path = PATHS.LEARNING_JOURNEY.LIST;
  let queryParams = { size: pageSize, start: pageOffset, type: tab.toUpperCase() };
  if (username) {
    queryParams = {
      ...queryParams,
      ...{ username },
    };
  }

  const query = qs.stringify(queryParams);

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${query}`, {
    withCredentials: true,
  });

  return response;
};

type GetLearningJourneyDetailParams = {
  journeyId: string;
};

const getLearningJourneyDetail = async (
  params: GetLearningJourneyDetailParams
): Promise<GetLearningJourneyDetail> => {
  const { journeyId } = params;

  const path = PATHS.LEARNING_JOURNEY.DETAIL({ id: journeyId });

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });

  return response;
};

type ListAllLearningJourneyCoursesParams = {
  journeyId: string;
  pageSize: number;
  pageOffset: number;
};

const listAllLearningJourneyCourses = async (
  params: ListAllLearningJourneyCoursesParams
): Promise<ListAllLearningJourneyCoursesResponse> => {
  const { pageSize, pageOffset, journeyId } = params;

  const path = PATHS.LEARNING_JOURNEY.COURSES({ id: journeyId });
  const queryParams = { size: pageSize, offset: pageOffset };
  const query = qs.stringify(queryParams);

  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?${query}`, {
    withCredentials: true,
  });

  return response;
};

const getLearningJourneyMetaData = (journeyId: string, id: string) => {
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    PATHS.LEARNING_JOURNEY.META_DATA({ journeyId, id }),
    { withCredentials: true }
  );
};

const getLearningJourneyTeams = (id: number, offset: number, limit: number, status: string) => {
  const queryStrings = qs.stringify({ id, offset, limit, status });
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${PATHS.LEARNING_JOURNEY.GET_TEAMS}?${queryStrings}`,
    {
      withCredentials: true,
    }
  );
};

const getLearningJourneyUsers = (id: number, offset: number, limit: number, status: string) => {
  const queryStrings = qs.stringify({ id, offset, limit, status });
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    `${PATHS.LEARNING_JOURNEY.GET_USERS}?${queryStrings}`,
    {
      withCredentials: true,
    }
  );
};

const userAssignLearningJourney = (payload: {
  username: string[];
  dueDate?: string;
  id: number;
}) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${PATHS.LEARNING_JOURNEY.ASSIGN_USERS}`, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

const teamAssignLearningJourney = (payload: {
  teamId: string;
  dueDate?: string;
  isRelative?: boolean;
  id: number;
}) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${PATHS.LEARNING_JOURNEY.ASSIGN_TEAM}`, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

const selfAssignLearningJourney = (id: number) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${PATHS.LEARNING_JOURNEY.ASSIGN_SELF}`, {
    body: { id },
    response: true,
    withCredentials: true,
  });
};

export {
  selfAssignLearningJourney,
  userAssignLearningJourney,
  teamAssignLearningJourney,
  getLearningJourneyDetail,
  listAllLearningJourneyCourses,
  listAllLearningJourneys,
  getLearningJourneyMetaData,
  getLearningJourneyTeams,
  getLearningJourneyUsers,
};
