import { API } from 'aws-amplify';
import qs from 'qs';
import { AMPLIFY_USHG_API_ENDPOINT_NAME } from '../../../constants';

import { UsersStatsFilter } from '../../teams/types/interface';
import { SubmitAssessmentArgsType } from '../components/SingleCourseView/types/SingleCourseTypes';
import {
  COMPLETE_MODULE,
  GET_ASSESSMENT_QUESTIONS,
  GET_COURSE_MODULE_SPEAKERS_PATH,
  GET_COURSES_CATEGORIES_FILTER_PATH,
  GET_COURSES_DETAILS_PATH,
  GET_COURSES_MODULES_PATH,
  GET_COURSES_PATH,
  GET_COURSES_SIMILAR_PATH,
  GET_COURSES_SPEAKERS_FILTER_PATH,
  GET_COURSES_SPEAKERS_PATH,
  GET_COURSES_TAGS_FILTER_PATH,
  GET_HQ_ADMIN_COURSES,
  GET_LEARNING_JOURNEY_RECOMMENDED_COURSES,
  GET_RECOMMENDED_COURSES,
  GET_TEAMS,
  GET_USER_FEEDBACK,
  GET_USERS,
  MEDIA_CHECK_POINT_UPDATE,
  POTENTIAL_BADGES,
  SELF_ASSIGN,
  SUBMIT_ANSWERS,
  TEAM_ASSIGN,
  UPDATE_MODULE_LAST_ACCESSED,
  USER_ASSIGN,
} from '../constants';
import { PAGE_LENGTH } from '../constants/data';
import { Courses, PartialUSHGHQAdminCoursesFilter } from '../types/interface';
import { GetPotentialBadgesResponse } from '../types/badge';

enum Filter {
  NONE = 'none',
  TAGS = 'tags',
  CATEGORIES = 'categories',
  DURATION = 'duration',
  SPEAKERS = 'speakers',
  FORMATS = 'formats',
  SORT = 'sort',
  SEARCH = 'search',
}

export const getCourseDetails = (id: string) => {
  const path = GET_COURSES_DETAILS_PATH(id);
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const getCourseSpeakers = (id: string) => {
  const path = GET_COURSES_SPEAKERS_PATH(id);
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const getCourseModuleSpeakers = (id: string) => {
  const path = GET_COURSE_MODULE_SPEAKERS_PATH(id);
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const getSimilarCourses = (id: string) => {
  const path = GET_COURSES_SIMILAR_PATH(id);
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const getCourseModules = (id: string) => {
  const path = GET_COURSES_MODULES_PATH(id);
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const getAssessmentQuestions = (id: string) => {
  const path = GET_ASSESSMENT_QUESTIONS(id);
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const getCourseFilterTags = () => {
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, GET_COURSES_TAGS_FILTER_PATH(), {
    withCredentials: true,
  });
};

export const getCourseFilterCategories = () => {
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, GET_COURSES_CATEGORIES_FILTER_PATH(), {
    withCredentials: true,
  });
};

export const getCourseFilterSpeakers = () => {
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, GET_COURSES_SPEAKERS_FILTER_PATH(), {
    withCredentials: true,
  });
};

const courseFilterPrepare = (queryVariable: string, filters: any[]) => {
  if (filters.length !== 0) {
    filters.forEach((f) => {
      if (f.type === Filter.TAGS) queryVariable += '&tag=' + f.value;
      else if (f.type === Filter.CATEGORIES) queryVariable += '&category=' + f.value;
      else if (f.type === Filter.FORMATS) queryVariable += '&format=' + f.value;
      else if (f.type === Filter.SPEAKERS) queryVariable += '&speaker=' + f.value;
      else if (f.type === Filter.DURATION) queryVariable += '&duration=' + f.value;
      else if (f.type === Filter.SORT) queryVariable += '&sort=' + f.value;
      else if (f.type === Filter.SEARCH) queryVariable += '&filter=' + encodeURIComponent(f.value);
    });
  }
  return queryVariable;
};

export const getCourses = (
  appliedFilters: any[],
  currentTab: string,
  startParam: any,
  size?: number,
  recommendType?: string
) => {
  let queryParams = '?';
  queryParams += 'type=' + currentTab.toUpperCase();
  queryParams += '&start=' + startParam;
  queryParams += `&size=${size ? size : PAGE_LENGTH}`;

  if (recommendType) queryParams += '&recommendation=' + recommendType;

  queryParams = courseFilterPrepare(queryParams, appliedFilters);
  const path = `${GET_COURSES_PATH}${queryParams}`;
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const getRecommendedCourses = (
  appliedFilters: any[],
  startParam: any,
  recommendType: string,
  size?: number
) => {
  let queryPath = `${GET_RECOMMENDED_COURSES(recommendType)}`;
  queryPath += '&offset=' + startParam;
  queryPath += `&limit=${size ? size : PAGE_LENGTH}`;
  queryPath = courseFilterPrepare(queryPath, appliedFilters);
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, queryPath, {
    withCredentials: true,
  });
};

export const getCoursesById = (
  appliedFilters: any[],
  currentTab: string,
  startParam: any,
  id?: Partial<UsersStatsFilter>
) => {
  let queryParams = '?';
  queryParams += 'type=' + currentTab.toUpperCase();
  queryParams += '&start=' + startParam;
  queryParams += `&size=${PAGE_LENGTH}`;
  queryParams += '&username=' + id?.username;

  queryParams = courseFilterPrepare(queryParams, appliedFilters);
  const path = `${GET_COURSES_PATH}${queryParams}`;
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const getHQAdminCourses = async (
  filters: PartialUSHGHQAdminCoursesFilter
): Promise<{
  count: number;
  data: Courses[];
}> => {
  const queryString = qs.stringify(filters);
  const path = GET_HQ_ADMIN_COURSES;
  const response = await API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${path}?` + queryString, {
    response: true,
    withCredentials: true,
  });
  return response.data;
};

export const getCoursesTeams = (
  courseId: number,
  offset: number,
  limit: number,
  status: string
) => {
  const queryStrings = qs.stringify({ courseId, offset, limit, status });
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${GET_TEAMS}?${queryStrings}`, {
    withCredentials: true,
  });
};

export const getCoursesUsers = (
  courseId: number,
  offset: number,
  limit: number,
  status: string
) => {
  const queryStrings = qs.stringify({ courseId, offset, limit, status });
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, `${GET_USERS}?${queryStrings}`, {
    withCredentials: true,
  });
};

export const selfAssignCourse = (courseId: number) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${SELF_ASSIGN}`, {
    body: { courseId },
    response: true,
    withCredentials: true,
  });
};

export const userAssignCourse = (payload: {
  courseId: number;
  username: string[];
  dueDate?: string;
}) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${USER_ASSIGN}`, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

export const teamAssignCourse = (payload: {
  courseId: number;
  teamId: string;
  dueDate?: string;
  isRelative?: boolean;
}) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, `${TEAM_ASSIGN}`, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

export const submitAnswers = (payload: SubmitAssessmentArgsType) => {
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, SUBMIT_ANSWERS, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

export const updateModuleLastAccessed = (assessmentId: string) => {
  return API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, UPDATE_MODULE_LAST_ACCESSED(assessmentId), {
    response: true,
    withCredentials: true,
  });
};

export const completeModule = (moduleId: number) => {
  return API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, COMPLETE_MODULE(moduleId), {
    response: true,
    withCredentials: true,
  });
};

export const getUserFeedback = (id: string, type: string) => {
  const path = `${GET_USER_FEEDBACK}?itemId=${id}&type=${type}`;
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const submitFeedback = (payload: any) => {
  const path = GET_USER_FEEDBACK;
  return API.post(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

export const getPotentialBadges = (id: number): Promise<GetPotentialBadgesResponse> => {
  const path = POTENTIAL_BADGES(id);
  return API.get(AMPLIFY_USHG_API_ENDPOINT_NAME, path, {
    withCredentials: true,
  });
};

export const mediaCheckPointUpdate = (payload: {
  moduleId: number;
  courseId: number;
  checkPointDuration: number;
}) => {
  return API.put(AMPLIFY_USHG_API_ENDPOINT_NAME, MEDIA_CHECK_POINT_UPDATE, {
    body: payload,
    response: true,
    withCredentials: true,
  });
};

export const learningJourneyCourses = (
  journeyId: string,
  start: number,
  size: number,
  excludeCourseStatuses?: string[]
) => {
  return API.get(
    AMPLIFY_USHG_API_ENDPOINT_NAME,
    GET_LEARNING_JOURNEY_RECOMMENDED_COURSES(journeyId, start, size, excludeCourseStatuses),
    {
      withCredentials: true,
    }
  );
};
