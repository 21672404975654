import clsx from 'clsx';
import AssignedLJView from '../../../learning-journeys/components/AssignedLJView';

import AssignedLJHeader from '../../../learning-journeys/components/LearningJourneyHeader/LearningJourneyAssignedHeader';
import { widgetData } from '../../../learning-journeys/constants/learning-journey';
import useListAllLearningJourneys from '../../../learning-journeys/hooks/use-list-all-learning-journeys';
import { UsersStatsFilter } from '../../types/interface';

type SinglePersonAssignedCourseProps = {
  className?: string;
  username: Partial<UsersStatsFilter>;
};

const SinglePersonAssignedLJ = ({ className, username }: SinglePersonAssignedCourseProps) => {
  const assignedFetch = useListAllLearningJourneys({
    tab: widgetData[2].value,
    username: username,
  });

  return (
    <>
      <div className={clsx('flex h-full flex-col gap-2 rounded-lg bg-zinc-800 p-5', className)}>
        <AssignedLJHeader className="bg-zinc-800" />
        <AssignedLJView className="!px-0" tab={assignedFetch} currentTabState={'assigned'} />
      </div>
    </>
  );
};

export default SinglePersonAssignedLJ;
