export const NOTIFICATION_PREFERENCE_CATEGORY = {
  SIGNUPS_PAYMENTS_REQUESTS_AND_COURSES: 'SIGNUPS_PAYMENTS_REQUESTS_AND_COURSES',
  USER_MANAGEMENT: 'USER_MANAGEMENT',
  COURSES_AND_BADGES: 'COURSES_AND_BADGES',
  LEARNING_JOURNEYS: 'LEARNING_JOURNEYS',
} as const;

export const NOTIFICATION_PREFERENCE_TYPE = {
  COURSE_ASSIGNED: 'COURSE_ASSIGNED',
  COURSE_DUE: 'COURSE_OVERDUE',
  LEARNING_JOURNEY_ASSIGNED: 'LEARNING_JOURNEY_ASSIGNED',
  NEW_BADGE_EARNED: 'NEW_BADGE_EARNED',
  NEW_ENTERPRISE_USER_ONBOARDED: 'NEW_USER_ONBOARDED',
  NEW_ENTERPRISE_TEAM_CREATED: 'NEW_TEAM_CREATED',
  NEW_SUPPORT_REQUEST_RAISED: 'NEW_SUPPORT_REQUEST_RAISED',
  NEW_HQ_SIGNUP: 'NEW_HQ_SIGNUP',
  SUBSCRIPTION_CANCELLED: 'SUBSCRIPTION_CANCELLED',
  PAYMENT_OVERDUE: 'PAYMENT_OVERDUE',
  NEW_COURSE_FEEDBACK_ADDED: 'NEW_COURSE_FEEDBACK_ADDED',
  NEW_ENTERPRISE_TEAM_REQUEST: 'NEW_ENTERPRISE_TEAM_REQUEST',
  NEW_ENTERPRISE_USER_REQUEST: 'NEW_ENTERPRISE_USER_REQUEST',
  ENTERPRISE_EDIT_TEAM_REQUEST: 'ENTERPRISE_EDIT_TEAM_REQUEST',
  ENTERPRISE_USER_TEAM_CHANGE: 'ENTERPRISE_USER_TEAM_CHANGE',
} as const;

export enum NOTIFICATION_MODES {
  WEB = 'WEB',
  EMAIL = 'EMAIL',
}

export const NOTIFICATION_MODES_CONFIG = {
  [NOTIFICATION_MODES.WEB]: {
    key: 'isWebEnabled',
    displayName: 'Web',
  },
  [NOTIFICATION_MODES.EMAIL]: {
    key: 'isEmailEnabled',
    displayName: 'Email',
  },
} as const;

export const NOTIFICATION_MODES_ORDER = [NOTIFICATION_MODES.WEB, NOTIFICATION_MODES.EMAIL];
