import { STATES } from '../../../constants/user';
import { TabWidgetType } from '../types/Filter.Helper';

// TODO: Revisit ?
export const DEFAULT_PAGE_SIZE = {
  JOURNEYS: {
    LIST: {
      ALL: 24,
      COURSES: 40,
    },
  },
};

export const LEARNING_JOURNEY_STATUS = {
  IN_PROGRESS: 'IN_PROGRESS',
  NOT_STARTED: 'NOT_STARTED',
  COMPLETED: 'COMPLETED',
  NO_COURSES: 'NO_COURSES',
  OVER_DUE: 'OVERDUE',
  ALL: 'ALL',
  ASSIGNED: 'ASSIGNED',
};

export const LEARNING_JOURNEY_ERROR_CODE = {
  LEARNING_JOURNEY_NOT_FOUND: 'LEARNING_JOURNEY_NOT_FOUND',
  COURSE_NOT_PART_OF_LEARNING_JOURNEY: 'COURSE_NOT_PART_OF_LEARNING_JOURNEY',
};

export enum Filter {
  TEAM = 'team',
  MEMBER = 'member',
}

export type filterType = {
  type: Filter;
  display: string;
  value: string;
  subText?: string;
};

export const MAX_USER_ASSIGN = 5;

export const widgetData: TabWidgetType[] = [
  {
    id: '1',
    text: 'In Progress',
    value: STATES.PROGRESS,
    subText: '0',
    isDue: false,
    isSelected: false,
  },
  {
    id: '2',
    text: 'OverDue',
    value: STATES.OVERDUE,
    subText: '0',
    isDue: true,
    isSelected: false,
  },
  {
    id: '3',
    text: 'Assigned',
    value: STATES.ASSIGNED,
    subText: '0',
    isDue: false,
    isSelected: false,
  },
  {
    id: '4',
    text: 'Completed',
    value: STATES.COMPLETED,
    subText: '0',
    isDue: false,
    isSelected: false,
  },
  {
    id: '5',
    text: 'All Learning Journey',
    value: STATES.ALL,
    subText: '0',
    isDue: false,
    isSelected: false,
  },
];
