import { twMerge } from 'tailwind-merge';
import useStrapiLearningJourneyData from '../../hooks/use-strapi-learning-journey-data';

type LJHeaderProps = {
  className?: string;
};

const AssignedLJHeader = ({ className }: LJHeaderProps) => {
  const { SinglePersonLJView } = useStrapiLearningJourneyData();
  const { ljAssignedTitle } = SinglePersonLJView;
  return (
    <div className={twMerge(`flex flex-col gap-3.5 bg-card-bg py-1 ${className}`)}>
      <p className="font-sans text-base font-bold text-white">{ljAssignedTitle}</p>
    </div>
  );
};

export default AssignedLJHeader;
