import { useInfiniteQuery } from '@tanstack/react-query';
import { useEffect, useState } from 'react';
import { useReduxAuthState } from '../../../hooks';
import { UsersStatsFilter } from '../../teams/types/interface';
import { listAllLearningJourneys } from '../api/learning-journey';
import { DEFAULT_PAGE_SIZE } from '../constants/learning-journey';
import { QUERY_KEYS, QUERY_STALE_TIME_MS } from '../helpers/tanstack-query';
import { ListAllLearningJourneysResponse } from '../types/api';

type useFetchLearningJourneyQueryProps = {
  tab: string;
  username?: Partial<UsersStatsFilter>;
};

const calculatePageOffset = (pageParam: number) =>
  (pageParam - 1) * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL;

const useListAllLearningJourneys = ({ tab, username }: useFetchLearningJourneyQueryProps) => {
  const auth = useReduxAuthState();

  if (!auth.isAuthenticated) {
    throw new Error('User should be authenticated');
  }

  const [response, setResponse] = useState<ListAllLearningJourneysResponse>({
    data: [],
    total: 0,
  });

  const { isLoading, error, data, fetchNextPage, isFetchingNextPage, hasNextPage } =
    useInfiniteQuery({
      queryKey: QUERY_KEYS.listAllLearningJourneys({
        username: auth.user.username,
        tab,
      }),
      queryFn: ({ pageParam = 1 }) => {
        const pageOffset = calculatePageOffset(pageParam);

        return listAllLearningJourneys({
          pageSize: DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL,
          pageOffset,
          tab,
          ...(username && { username }),
        });
      },
      getNextPageParam: (lastPage, allPages) => {
        const fetchedCount = allPages.length * DEFAULT_PAGE_SIZE.JOURNEYS.LIST.ALL;
        const totalCount = lastPage.total;

        if (fetchedCount >= totalCount) {
          return undefined;
        }

        return allPages.length + 1;
      },
      staleTime: QUERY_STALE_TIME_MS.listAllLearningJourneys,
    });

  useEffect(() => {
    if (!data?.pages) return;

    // Combine all pages data into a single response
    const combinedData = data.pages.reduce(
      (acc, page) => ({
        data: [...acc.data, ...page.data],
        total: page.total,
      }),
      { data: [], total: 0 }
    );

    setResponse(combinedData);
  }, [data]);

  return {
    isLoading,
    error,
    data: response,
    fetchNextPage,
    isFetchingNextPage,
    hasNextPage,
  };
};

export default useListAllLearningJourneys;
