import { useMutation } from '@tanstack/react-query';
import { userAssignLearningJourney } from '../api/learning-journey';

const useAssignLearningJourneyMemberMutation = () => {
  const { data, error, isLoading, isError, mutate, isSuccess } = useMutation({
    mutationFn: userAssignLearningJourney,
  });
  return { data, error, isLoading, isError, mutate, isSuccess };
};

export default useAssignLearningJourneyMemberMutation;
