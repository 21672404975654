export const COURSES = 'Course';
export const DETAILS = 'Details';
export const MODULES = 'Modules';
export const SIMILAR = 'Similar';

export const TOAST_NETWORK_ERROR_TITLE = 'There is an error!';
export const TOAST_ERROR_MESSAGE = 'Something went wrong!';
export const TOAST_LOADING_SUBTITLE = (content: string) => {
  return `Failed to load ${content}`;
};

export const TOAST_SUBMIT_SUBTITLE = 'Failed to submit answers';
export const STALE_TIME = 3000;
export const TOAST_PRE_SIGNED_SUBTITLE = 'Failed to load media';
export const TOAST_DURATION = 3000;

export const SINGLE_COURSE_STALE_TIME = 30 * 60 * 1000; // 30mins
