import { Link, useNavigate } from 'react-router-dom';
import { queryClient } from '../../../../../../config/react-query';
import { useReduxAuthState } from '../../../../../../hooks';
import cn from '../../../../../../utils/cn';
import { LEARNING_JOURNEY_STATUS } from '../../../../constants/learning-journey';
import { STRAPI_PLACEHOLDER_CODE as GENERIC_STRAPI_PLACEHOLDER_CODE } from '../../../../../../constants/strapi';
import { STRAPI_PLACEHOLDER_CODE } from '../../../../constants/strapi';
import { QUERY_KEYS } from '../../../../helpers/tanstack-query';
import useStrapiLearningJourneyData from '../../../../hooks/use-strapi-learning-journey-data';
import type { GetLearningJourneyDetail, LearningJourney } from '../../../../types/api';
import ImageWithFallback from '../../../../../../components/ImageWithFallback';

interface Props {
  learningJourney: LearningJourney;
  className?: string;
}

// Revisit this logic again
const getLearningJourneyStatus = (learningJourney: LearningJourney) => {
  const { stats, totalCount } = learningJourney.courses;

  const parsedTotalCount = parseInt(totalCount);

  if (parsedTotalCount === 0) {
    return LEARNING_JOURNEY_STATUS.NO_COURSES;
  }

  if (stats.inProgress > 0) {
    return LEARNING_JOURNEY_STATUS.IN_PROGRESS;
  } else {
    // No in progress courses
    if (stats.completed === parsedTotalCount) {
      return LEARNING_JOURNEY_STATUS.COMPLETED;
    } else if (stats.completed > 0) {
      return LEARNING_JOURNEY_STATUS.IN_PROGRESS;
    } else {
      // completed == 0 and inProgress == 0
      return LEARNING_JOURNEY_STATUS.NOT_STARTED;
    }
  }
};

const LearningJourneyCard = (props: Props) => {
  const { learningJourney, className } = props;

  const status = getLearningJourneyStatus(learningJourney);

  const { LearningJourneyCard: cms } = useStrapiLearningJourneyData();

  // state
  const auth = useReduxAuthState();

  // hooks
  const navigate = useNavigate();

  const statusLabelMap = {
    [LEARNING_JOURNEY_STATUS.IN_PROGRESS]: cms.inProgressBadgeLabel,
    [LEARNING_JOURNEY_STATUS.COMPLETED]: cms.completedBadgeLabel,
    [LEARNING_JOURNEY_STATUS.NOT_STARTED]: cms.notStartedBadgeLabel,
  };

  const learningJourneyPath = `/learning-journeys/${learningJourney.id}`;

  const navigateToLearningJourneyDetail: React.MouseEventHandler<HTMLAnchorElement> = (e) => {
    e.preventDefault();

    // set query data
    queryClient.setQueryData(
      QUERY_KEYS.learningJourneyDetail({
        id: learningJourney.id,
        username: auth.user?.username as string,
      }),
      () => {
        return {
          id: learningJourney.id,
          description: learningJourney.description,
          name: learningJourney.name,
          isAssigned: learningJourney.isAssigned,
          courses: {
            totalCount: learningJourney.courses.totalCount,
          },
        } as GetLearningJourneyDetail;
      }
    );

    // navigate to detail page
    navigate(learningJourneyPath);
  };

  return (
    <Link
      to={learningJourneyPath}
      className={cn('group flex w-full items-stretch', className)}
      aria-label={learningJourney.name}
      onClick={navigateToLearningJourneyDetail}
    >
      <article
        className="flex w-full flex-col gap-3 rounded-2xl border-2 border-base-soft-black px-[var(--card-padding-x)] py-[var(--card-padding-y)] xs:gap-4 xs:border-[3px] sm:border-4 md:gap-5 lg:gap-6"
        style={{
          background:
            'linear-gradient(180deg,rgba(171,165,110,0.85) 0%,rgba(234,226,194,0.85) 100%),rgba(255,255,255)',
        }}
      >
        {/* Thumbnail */}
        <div
          className="relative min-h-[var(--min-thumbnail-height)] min-w-[var(--min-thumbnail-width)] overflow-hidden rounded-md"
          style={{ transform: 'translateZ(0)' }}
        >
          <div className="relative aspect-[var(--lj-card-thumbnail-aspect-ratio)] w-full pb-[37.5%]">
            <ImageWithFallback
              imageKey={learningJourney.thumbnailUrl}
              alt={cms.thumbnailAlt.replace(
                STRAPI_PLACEHOLDER_CODE.LEARNING_JOURNEY_NAME,
                learningJourney.name
              )}
              width={800}
              height={300}
              className="absolute inset-0 h-auto max-w-full rounded-md object-cover object-center transition-transform will-change-transform group-hover:scale-105"
              Fallback={
                <div
                  className={cn('absolute inset-0 h-full w-full rounded-md', {
                    'bg-base-brand': status === LEARNING_JOURNEY_STATUS.IN_PROGRESS,
                    'bg-base-soft-black': status === LEARNING_JOURNEY_STATUS.NOT_STARTED,
                    'bg-base-green': status === LEARNING_JOURNEY_STATUS.COMPLETED,
                    'bg-base-tangerine': status === LEARNING_JOURNEY_STATUS.NO_COURSES,
                  })}
                ></div>
              }
            />
            {/* Learning Journey Status */}
            {status ? (
              <span
                className={cn(
                  'absolute right-3 top-3 rounded-[32px] px-2.5 py-0.5 text-[0.625rem] font-bold leading-[150%] tracking-[0.0125em] text-white',
                  {
                    'bg-base-brand': status === LEARNING_JOURNEY_STATUS.IN_PROGRESS,
                    'bg-base-soft-black': status === LEARNING_JOURNEY_STATUS.NOT_STARTED,
                    'bg-base-green': status === LEARNING_JOURNEY_STATUS.COMPLETED,
                  }
                )}
              >
                {statusLabelMap[status]}
              </span>
            ) : null}
          </div>
        </div>
        {/* Content */}
        <div className="flex flex-col gap-1 md:gap-1.5">
          <h2 className="text-[0.625rem] font-semibold tracking-[0.99px] text-base-brand md:text-xs">
            {cms.coursesCountLabel.replace(
              GENERIC_STRAPI_PLACEHOLDER_CODE.COUNT,
              `${learningJourney.courses.totalCount}`
            )}
          </h2>
          <h3 className="text-xl font-bold leading-normal tracking-[-0.446px] text-base-soft-black lg:text-[1.375rem]">
            {learningJourney.name}
          </h3>
          <p className="text-xs font-normal text-zinc-600 line-clamp-3">
            {learningJourney.description}
          </p>
        </div>
      </article>
    </Link>
  );
};

export default LearningJourneyCard;
