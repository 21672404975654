import { useEffect, useState } from 'react';
import useStrapiLearningJourneyData from '../../../../hooks/use-strapi-learning-journey-data';
import { useLocation, useSearchParams } from 'react-router-dom';
import TabWidgets from '../TabWidgets';
import LearningJourneyView from '../LearningJourneyView';
import { STATES } from '../../../../../../constants/user';
import { TabWidgetType } from '../../../../types/Filter.Helper';
import useListAllLearningJourneys from '../../../../hooks/use-list-all-learning-journeys';
import useStatusCount from '../../../../hooks/use-status-count';

const LearningJourneysList = () => {
  const [currentTab, setCurrentTab] = useState<TabWidgetType>();
  const [searchParam, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [onMount, setOnMount] = useState<boolean>(true);

  const { ListAllLJs } = useStrapiLearningJourneyData();
  const { LJStatus: status, LJNavigator: navigator } = ListAllLJs;

  // Fetch all queries but properly handle their states

  const progressFetch = useListAllLearningJourneys({
    tab: STATES.PROGRESS,
  });

  const overdueFetch = useListAllLearningJourneys({
    tab: STATES.OVERDUE,
  });

  const assignedFetch = useListAllLearningJourneys({
    tab: STATES.ASSIGNED,
  });

  const completedFetch = useListAllLearningJourneys({
    tab: STATES.COMPLETED,
  });

  const allCoursesFetch = useListAllLearningJourneys({
    tab: STATES.ALL,
  });

  useEffect(() => {
    if (
      onMount &&
      location.state?.auto &&
      (searchParam.get('view') === STATES.PROGRESS || searchParam.get('view') === STATES.ASSIGNED)
    ) {
      if (progressFetch.data && assignedFetch.data) {
        // If in progress is empty, try assigned
        if (progressFetch.data.total === 0) {
          // If assigned is also empty, go to ALL
          if (assignedFetch.data.total === 0) {
            setSearchParams({ view: STATES.ALL });
          }
          // If assigned has data, go there
          else {
            setSearchParams({ view: STATES.ASSIGNED });
          }
        }
        setOnMount(false);
      }
    }
  }, [progressFetch.data, assignedFetch.data, searchParam.get('view'), location.state]);
  const navigateToAllLJs = () => setSearchParams({ view: STATES.ALL });

  const widgets = useStatusCount({
    progressCount: progressFetch.data?.total ?? 0,
    overdueCount: overdueFetch.data?.total ?? 0,
    assignedCount: assignedFetch.data?.total ?? 0,
    completedCount: completedFetch.data?.total ?? 0,
    allCount: allCoursesFetch.data?.total ?? 0,
  });

  return (
    <div className="flex w-full flex-col gap-5">
      <TabWidgets
        className="mt-5"
        selectedWidget={currentTab}
        setSelectedWidget={setCurrentTab}
        widgetData={widgets}
      />
      <main id="main-content">
        {currentTab?.value === STATES.PROGRESS && (
          <LearningJourneyView
            tab={progressFetch}
            currentTabState={status.inProgressLabel}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
        {currentTab?.value === STATES.OVERDUE && (
          <LearningJourneyView
            tab={overdueFetch}
            currentTabState={status.overDueLabel}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
        {currentTab?.value === STATES.ASSIGNED && (
          <LearningJourneyView
            tab={assignedFetch}
            currentTabState={status.assignedLabel}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
        {currentTab?.value === STATES.COMPLETED && (
          <LearningJourneyView
            tab={completedFetch}
            currentTabState={status.completedLabel}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
        {currentTab?.value === STATES.ALL && (
          <LearningJourneyView
            tab={allCoursesFetch}
            currentTabState={navigator.allLJs}
            navigateToAllLJs={navigateToAllLJs}
          />
        )}
      </main>
    </div>
  );
};

export default LearningJourneysList;
