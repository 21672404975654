import { useMemo } from 'react';
import { useParams } from 'react-router-dom';
import { CourseCard } from '../../../../../components/Cards';
import InfiniteScrollV1 from '../../../../../components/InfiniteScroll/InfiniteScrollV1';
import cn from '../../../../../utils/cn';
import useListLearningJourneyCourses from '../../../hooks/use-list-learning-journey-courses';
import useStrapiLearningJourneyData from '../../../hooks/use-strapi-learning-journey-data';
import EmptyState from '../../EmptyState';

type Props = {
  className?: string;
};

const LearningJourneyCoursesList = (props: Props) => {
  const { className } = props;

  // strapi
  const { LearningJourneyDetail: cms } = useStrapiLearningJourneyData();

  const { id } = useParams() as { id: string };

  // tanstack query
  const journeyCourseQuery = useListLearningJourneyCourses({ id });

  // derived state
  const hasData = journeyCourseQuery.data && journeyCourseQuery.data.pages.length > 0;
  const learningJourneyCourses = useMemo(() => {
    if (!hasData) {
      return [];
    }

    return journeyCourseQuery.data.pages.map((page) => [...page.results]).flat();
  }, [journeyCourseQuery.data, hasData]);

  if (hasData && learningJourneyCourses.length === 0) {
    return (
      <EmptyState
        action={{
          message: cms.checkoutOtherJourneysMessage,
          path: '/learning-journeys',
        }}
        message={cms.noCoursesForJourneyMessage}
      />
    );
  }

  return (
    <InfiniteScrollV1
      className="relative items-stretch p-4 xs:p-5 sm:p-6 md:p-7 lg:px-9"
      fetchNextPage={journeyCourseQuery.fetchNextPage}
      isError={journeyCourseQuery.isError}
      isFetchingNextPage={journeyCourseQuery.isFetchingNextPage}
      isInitialLoading={journeyCourseQuery.isLoading}
      hasNextPage={journeyCourseQuery.hasNextPage ?? false}
      allItemsFetchedMessage={cms.allCoursesFetched}
      loadingMoreItemsMessage={cms.loadingMoreCourses}
      errorMessage={cms.errorLoadingCourses}
      refetch={journeyCourseQuery.refetch}
      isRefetching={journeyCourseQuery.isRefetching}
      hasData={hasData}
      allowsRefetch={true}
      isMoreThanOnePage={journeyCourseQuery.data?.pages && journeyCourseQuery.data.pages.length > 1}
      rootMargin="240px"
      showReachedEndMessage={false}
    >
      <ul
        className={cn(
          'grid grid-cols-[repeat(auto-fill,minmax(var(--min-course-width),1fr))] items-stretch gap-4 [--min-course-width:10rem] xxs:[--min-course-width:15rem] md:gap-5',
          className
        )}
      >
        {learningJourneyCourses.map((learningJourneyCourse) => {
          return (
            <li key={learningJourneyCourse.id} className="group relative">
              <CourseCard
                course={learningJourneyCourse}
                isThumbnailKeyToCDNAssets={true}
                useThumbnailAndGradientAsBg={true}
                source="learning-journey"
                learningJourneyId={id}
                disableClick={!learningJourneyCourse.isAssigned}
                tooltipMessage={cms.startLJ}
              />
              <div className="absolute top-3 left-4 rounded-md border-[0.851px] bg-base-creme py-2 px-3 text-lg font-extrabold leading-[1.125rem] text-base-soft-black group-hover:hidden">
                {learningJourneyCourse.sort}
              </div>
              {/* </div> */}
            </li>
          );
        })}
      </ul>
    </InfiniteScrollV1>
  );
};

export default LearningJourneyCoursesList;
