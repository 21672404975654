import { CoursesHeader, TabWidgets } from './CoursesView/components';
import { useEffect, useRef, useState } from 'react';
import { Filter, filterType, TabWidgetType } from '../types/Filter.Helper';
import { CoursesView } from './CoursesView';
import useFetchCoursesQuery from '../hooks/useFetchCoursesQuery';
import useStatusCount from '../hooks/useStatusCount';
import { useAppDispatch } from '../../../hooks';
import { setCategoryName, setFormat, setSpeakers, setTags } from '../slices/courses.slice';
import { useLocation, useSearchParams } from 'react-router-dom';
import { RecommendedCourses } from './CoursesView/components/RecommendedCourses';
import { CourcesHeaderRefObj } from './CoursesView/components/CoursesHeader/CoursesHeader';
import { useStrapiCoursesData } from '../hooks/useStrapiCourseData';
import { STATES } from '../../../constants/user';

const Courses = () => {
  const [appliedFilters, setAppliedFilters] = useState<filterType[]>([]);
  const [currentTab, setCurrentTab] = useState<TabWidgetType>();
  const dispatch = useAppDispatch();
  const [searchParam, setSearchParams] = useSearchParams();
  const location = useLocation();
  const [onMount, setOnMount] = useState<boolean>(true);
  const progressFetch = useFetchCoursesQuery({
    tab: STATES.PROGRESS,
    filters: appliedFilters,
  });
  const overdueFetch = useFetchCoursesQuery({
    tab: STATES.OVERDUE,
    filters: appliedFilters,
  });

  const assignedFetch = useFetchCoursesQuery({
    tab: STATES.ASSIGNED,
    filters: appliedFilters,
  });

  const completedFetch = useFetchCoursesQuery({
    tab: STATES.COMPLETED,
    filters: appliedFilters,
  });

  const allCoursesFetch = useFetchCoursesQuery({
    tab: STATES.ALL,
    filters: appliedFilters,
  });

  useEffect(() => {
    if (
      onMount &&
      location.state?.auto &&
      (searchParam.get('view') === STATES.PROGRESS || searchParam.get('view') === STATES.ASSIGNED)
    ) {
      if (progressFetch.data && assignedFetch.data) {
        if (
          progressFetch.data.pages[0].totalCount === 0 &&
          assignedFetch.data.pages[0].totalCount === 0
        )
          setSearchParams({ view: STATES.RECOMMENDED });
        else if (progressFetch.data.pages[0].totalCount === 0)
          setSearchParams({ view: STATES.ASSIGNED });
        setOnMount(false);
      }
    }
  }, [progressFetch.data, assignedFetch.data, searchParam.get('view'), location.state]);

  const navigateToAllCourses = () => setSearchParams({ view: STATES.ALL });

  const { allCourses } = useStrapiCoursesData();
  const { navigator, status } = allCourses;

  const widgets = useStatusCount({
    progressCount: progressFetch.totalCount,
    overdueCount: overdueFetch.totalCount,
    assignedCount: assignedFetch.totalCount,
    completedCount: completedFetch.totalCount,
    allCount: allCoursesFetch.totalCount,
  });

  const courseHeaderRef = useRef<CourcesHeaderRefObj>(null);

  const clearAllFilters = () => {
    courseHeaderRef?.current?.clearFiltersHandler();
  };

  useEffect(() => {
    if (allCoursesFetch.data?.pages[0]?.facets) {
      dispatch(setCategoryName(allCoursesFetch.data?.pages[0]?.facets.category_name));
      dispatch(setFormat(allCoursesFetch.data?.pages[0]?.facets.format));
      dispatch(setSpeakers(allCoursesFetch.data?.pages[0]?.facets.speakers));
      dispatch(setTags(allCoursesFetch.data?.pages[0]?.facets.tags));
    }
  }, [allCoursesFetch]);

  return (
    <div className="flex w-full flex-col gap-5">
      <CoursesHeader
        ref={courseHeaderRef}
        appliedFilters={appliedFilters}
        setAppliedFilters={setAppliedFilters}
        showFilteredByWidgets={
          appliedFilters.length > 1 ||
          (appliedFilters[0] && appliedFilters[0].type !== Filter.SEARCH)
        }
      />
      <TabWidgets
        selectedWidget={currentTab}
        setSelectedWidget={setCurrentTab}
        widgetData={widgets}
      />
      <main id="main-content">
        {currentTab?.value === STATES.PROGRESS && (
          <CoursesView
            tab={progressFetch}
            currentTabState={status.inProgressLabel}
            navigateToAllCourses={navigateToAllCourses}
          />
        )}
        {currentTab?.value === STATES.OVERDUE && (
          <CoursesView
            tab={overdueFetch}
            currentTabState={status.overDueLabel}
            navigateToAllCourses={navigateToAllCourses}
          />
        )}
        {currentTab?.value === STATES.ASSIGNED && (
          <CoursesView
            tab={assignedFetch}
            currentTabState={status.assignedLabel}
            navigateToAllCourses={navigateToAllCourses}
          />
        )}
        {currentTab?.value === STATES.COMPLETED && (
          <CoursesView
            tab={completedFetch}
            currentTabState={status.completedLabel}
            navigateToAllCourses={navigateToAllCourses}
          />
        )}
        {currentTab?.value === STATES.RECOMMENDED && (
          <RecommendedCourses
            className="px-8"
            appliedFilters={appliedFilters}
            navigateToAllCourses={navigateToAllCourses}
          />
        )}
        {currentTab?.value === STATES.ALL && (
          <CoursesView
            tab={allCoursesFetch}
            currentTabState={navigator.allCoursesTab}
            navigateToAllCourses={navigateToAllCourses}
            triggerClearAllFilters={clearAllFilters}
          />
        )}
      </main>
    </div>
  );
};

export default Courses;
