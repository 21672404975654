import { useMutation } from '@tanstack/react-query';
import { selfAssignLearningJourney } from '../api/learning-journey';

const useSelfAssignLJMutation = () => {
  const { data, error, isLoading, isError, mutate, isSuccess } = useMutation({
    mutationFn: selfAssignLearningJourney,
  });
  return { data, error, isLoading, isError, mutate, isSuccess };
};

export default useSelfAssignLJMutation;
