import { useMutation } from '@tanstack/react-query';
import { teamAssignLearningJourney } from '../api/learning-journey';

const useAssignLearningJourneyTeamMutation = () => {
  const { data, error, isLoading, isError, mutate, isSuccess } = useMutation({
    mutationFn: teamAssignLearningJourney,
  });
  return { data, error, isLoading, isError, mutate, isSuccess };
};

export default useAssignLearningJourneyTeamMutation;
