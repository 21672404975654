import illustrationSrc from '../../../../assets/SuccessIllustrator.png';
import { ArrowCounterClockwise, ArrowRight, Check } from 'phosphor-react';
import { Link } from 'react-router-dom';
import CourseWrapperComponent from '../../../CourseWrapper/CourseWrapperComponent';
import {
  MORE_COURSES_DISPLAY_LIMIT,
  RECOMMENDATION_TYPE,
} from '../../../../../../constants/course';
import useLearningJourneyCourses from '../../../../../../hooks/useLearningJourneyCourses';
import { USER_COURSE_STATUS } from '../../../../../../../dashboard/types';
import useSimilarCoursesQuery from '../../../../../../hooks/useSimilarCoursesQuery';
import useRecommendedCoursesFetch from '../../../../../../hooks/useRecommendedCoursesFetch';
import useLearningJourneyDetail from '../../../../../../../learning-journeys/hooks/use-learning-journey-detail';
import { STRAPI_PLACEHOLDER_CODE } from '../../../../constants/strapi';
import { useStrapiCoursesData } from '../../../../../../hooks/useStrapiCourseData';
import { STATES } from '../../../../../../../../constants/user';

type props = {
  courseId: string;
  journeyId?: string;
  courseCategory: string;
  resetHandler: () => void;
};

const CourseCompletionComponent = ({
  courseCategory,
  courseId,
  journeyId,
  resetHandler,
}: props) => {
  const { singleCourseCompleted, courseCompleted } = useStrapiCoursesData();

  // Course completion
  const {
    restartCourseButton,
    findMoreCourseButton,
    recommendedCourseLabel,
    otherCourseInCategoryLabel,
    learningJourneyCourseCompleted,
    completedLearningJourney,
    exploreMoreLearningJourney,
    nextUpInLearningJourney,
  } = singleCourseCompleted;

  const { title, description } = courseCompleted;

  // Fetch Learning Journey Courses
  const {
    data: learningJourneyData,
    isLoading: learningJourneyLoading,
    isError: learningJourneyError,
  } = useLearningJourneyCourses({
    learningJourneyId: journeyId,
    excludeCourseStatuses: [USER_COURSE_STATUS.COMPLETED],
    size: MORE_COURSES_DISPLAY_LIMIT,
    enabled: !!journeyId,
  });

  const learningJourneyDetail = useLearningJourneyDetail({
    id: `${journeyId}`,
    enabled: !!journeyId,
  });

  // Fetch Similar Courses
  const {
    data: similarCoursesData,
    isLoading: isLoadingSimilarCourses,
    isError: isErrorSimilarCourses,
  } = useSimilarCoursesQuery({ courseId, enabled: !journeyId });

  // Fetch Personal Recommendation
  const personalRecommendationFetch = useRecommendedCoursesFetch({
    type: RECOMMENDATION_TYPE.PERSONAL,
    filters: [],
    enabled: similarCoursesData && similarCoursesData.length === 0,
  });

  const isAllCoursesCompletedInJourney = journeyId ? learningJourneyData?.length === 0 : false;

  const {
    isLoading: isPersonalRecommendationLoading,
    isError: isPersonalRecommendationError,
    data: personalRecommendationData,
  } = personalRecommendationFetch;

  const otherCourseInCategoryLabelSubstitued = otherCourseInCategoryLabel.replace(
    STRAPI_PLACEHOLDER_CODE.COURSE_CATEGORY,
    courseCategory
  );

  const getLearningJourney = (learningJourneyName: string) => {
    const learningJourneyBuild = nextUpInLearningJourney.split('{{learning_journey}}');
    return (
      <>
        {learningJourneyBuild[0]}
        <Link to={`/learning-journeys/${journeyId}`} className="text-base-brand hover:underline">
          {learningJourneyName}
        </Link>
        {learningJourneyBuild[1]}
      </>
    );
  };

  return (
    <div
      className="flex h-auto w-full flex-col gap-4 rounded-lg border border-zinc-700 bg-zinc-900 bg-cover p-5"
      style={{
        backgroundImage: `linear-gradient(rgba(0,0,0,0.25),rgba(0,0,0,0.25)), url(${illustrationSrc})`,
      }}
    >
      <div className="flex flex-col items-center rounded-lg border border-[#34D3991A] bg-[#064E3B1A] p-8 text-center">
        <div className="flex flex-col items-center gap-3">
          <div className="flex aspect-square h-max w-max flex-col items-center justify-center rounded-full bg-emerald-600 p-2">
            <Check size={24} color="#ffffff" />
          </div>
          <div className="flex flex-col items-center gap-2">
            <p className="header-title font-bold">{title}</p>
            <div className="flex flex-col items-center gap-1">
              <p className="text-sm text-emerald-200">
                {journeyId
                  ? isAllCoursesCompletedInJourney
                    ? completedLearningJourney
                    : learningJourneyCourseCompleted
                  : description}
              </p>
              <div className="flex flex-row gap-4">
                <div onClick={resetHandler}>
                  <button className="flex cursor-pointer flex-row items-center gap-1 border-b border-b-transparent text-sm text-emerald-200 hover:border-b-emerald-200">
                    <ArrowCounterClockwise size={18} className="" /> {restartCourseButton}
                  </button>
                </div>
                {(!journeyId || isAllCoursesCompletedInJourney) && (
                  <Link
                    className="flex cursor-pointer items-center gap-1 border-b border-b-transparent text-sm text-emerald-200 hover:border-b-emerald-200"
                    to={journeyId ? '/learning-journeys' : `/courses?view=${STATES.ALL}`}
                  >
                    <span>{journeyId ? exploreMoreLearningJourney : findMoreCourseButton}</span>
                    <ArrowRight color="currentColor" />
                  </Link>
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* similar courses */}
      {!journeyId && (
        <CourseWrapperComponent
          headerClassName="text-sm"
          label={otherCourseInCategoryLabelSubstitued}
          coursesData={similarCoursesData}
          isLoading={isLoadingSimilarCourses}
          isError={isErrorSimilarCourses}
          coursesDisplayLimit={MORE_COURSES_DISPLAY_LIMIT}
        />
      )}

      {!journeyId && similarCoursesData?.length === 0 && (
        <CourseWrapperComponent
          headerClassName="text-sm"
          label={recommendedCourseLabel}
          coursesData={personalRecommendationData?.results}
          isLoading={isPersonalRecommendationLoading}
          isError={isPersonalRecommendationError}
          coursesDisplayLimit={MORE_COURSES_DISPLAY_LIMIT}
        />
      )}

      {journeyId && (
        <CourseWrapperComponent
          headerClassName="text-sm"
          label={getLearningJourney(learningJourneyDetail?.data?.name ?? '')}
          coursesData={learningJourneyData?.filter((course: any) => course.id != courseId)}
          isLoading={learningJourneyLoading}
          isError={learningJourneyError}
          coursesDisplayLimit={MORE_COURSES_DISPLAY_LIMIT}
          source={'learning-journey'}
          showSort={true}
          learnJourneyId={journeyId}
        />
      )}
    </div>
  );
};

export default CourseCompletionComponent;
